var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{staticClass:"img-logo-n",attrs:{"src":require("@/assets/images/logo/logo.png")}})]),_c('div',{staticStyle:{"position":"absolute","right":"0","padding":"30px","z-index":"1000"}},[_c('b-link',{on:{"click":function($event){_vm.$i18n.locale = 'en'}}},[_vm._v(" EN ")]),_vm._v(" | "),_c('b-link',{on:{"click":function($event){_vm.$i18n.locale = 'id'}}},[_vm._v(" ID ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",staticStyle:{"margin-top":"150px"},attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("form.page.registration.Title"))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("form.page.registration.Sub Title"))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addNewLead($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.page.registration.Fullname'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.registrationForm.name),callback:function ($$v) {_vm.$set(_vm.registrationForm, "name", $$v)},expression:"registrationForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form.page.registration.Email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.registrationForm.userEmail),callback:function ($$v) {_vm.$set(_vm.registrationForm, "userEmail", $$v)},expression:"registrationForm.userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('form.page.registration.Handphone'),"label-for":"handphone"}},[_c('validation-provider',{attrs:{"name":"Handphone","vid":"handphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"handphone","name":"handphone","state":errors.length > 0 ? false : null,"placeholder":"081300000","type":"number"},model:{value:(_vm.registrationForm.handphone),callback:function ($$v) {_vm.$set(_vm.registrationForm, "handphone", $$v)},expression:"registrationForm.handphone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"address","label":_vm.$t('form.page.registration.Address')}},[_c('validation-provider',{attrs:{"name":"Address","vid":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.registrationForm.address),callback:function ($$v) {_vm.$set(_vm.registrationForm, "address", $$v)},expression:"registrationForm.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Agreement","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},on:{"change":_vm.changeCheck},model:{value:(_vm.registrationForm.status),callback:function ($$v) {_vm.$set(_vm.registrationForm, "status", $$v)},expression:"registrationForm.status"}},[_vm._v(" "+_vm._s(_vm.$t("form.page.registration.Agree"))+" "),_c('b-link',[_vm._v(_vm._s(_vm.$t("form.page.registration.Term Policy")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('vue-recaptcha',{ref:"invisibleRecaptcha",attrs:{"sitekey":'6LcevKcaAAAAAMruo7J2SlR8s04ognHvi-0Wween',"loadRecaptchaScript":true},on:{"verify":_vm.onVerify,"expired":_vm.onExpired}})],1),_c('b-button',{attrs:{"disabled":invalid,"variant":"primary","block":"","type":"submit"}},[_vm._v(_vm._s(_vm.$t("form.page.registration.Sign Up")))])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("form.page.registration.Already have an account")))]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("form.page.registration.Back To Login")))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }