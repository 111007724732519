<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link> -->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo/logo.png" class="img-logo-n" />
      </b-link>
      <div style="position: absolute; right: 0; padding: 30px; z-index: 1000">
        <b-link @click="$i18n.locale = 'en'"> EN </b-link>
        |
        <b-link @click="$i18n.locale = 'id'"> ID </b-link>
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto" style="margin-top: 150px">
          <b-card-title class="mb-1">
            {{ $t("form.page.registration.Title") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("form.page.registration.Sub Title") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="addNewLead">
              <!-- username -->
              <b-form-group
                :label="$t('form.page.registration.Fullname')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="registrationForm.name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('form.page.registration.Email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="registrationForm.userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                :label="$t('form.page.registration.Handphone')"
                label-for="handphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Handphone"
                  vid="handphone"
                  rules="required"
                >
                  <b-form-input
                    id="handphone"
                    v-model="registrationForm.handphone"
                    name="handphone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="081300000"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Address -->
              <b-form-group
                label-for="address"
                :label="$t('form.page.registration.Address')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  vid="address"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-textarea
                      v-model="registrationForm.address"
                      id="textarea-default"
                      placeholder="Textarea"
                      rows="3"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Agreement"
                  vid="status"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="registrationForm.status"
                    name="checkbox-1"
                    @change="changeCheck"
                  >
                    {{ $t("form.page.registration.Agree") }}
                    <b-link>{{ $t("form.page.registration.Term Policy") }}</b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <vue-recaptcha
                  ref="invisibleRecaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :sitekey="'6LcevKcaAAAAAMruo7J2SlR8s04ognHvi-0Wween'"
                  :loadRecaptchaScript="true"
                ></vue-recaptcha>
              </b-form-group>

              <b-button :disabled="invalid" variant="primary" block type="submit">{{
                $t("form.page.registration.Sign Up")
              }}</b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t("form.page.registration.Already have an account") }}</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;{{ $t("form.page.registration.Back To Login") }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script
  src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"
  defer
></script>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  async
  defer
></script>

<script>
// key for testing only '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import VueRecaptcha from "vue-recaptcha";
import axios from "@axios";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      registrationForm: {
        name: null,
        userEmail: null,
        address: null,
        handphone: null,
        captcha: null,
        status: null,
      },
      // name: "",
      // userEmail: "",
      // address: "",
      // handphone: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          useJwt
            .register({
              name: this.name,
              email: this.userEmail,
              address: this.address,
            })
            .then((response) => {
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem("userData", JSON.stringify(response.data.userData));
              this.$ability.update(response.data.userData.ability);
              this.$router.push("/");
            })
            .catch((error) => {
              this.$refs.registerForm.setErrors(error.response.data.error);
            });
        }
      });
    },
    onSubmit: function () {
      if (this.registrationForm.captcha) {
        this.$refs.registerForm.validate().then((success) => {
          if (success) {
            useJwt
              .register({
                name: this.name,
                email: this.userEmail,
                address: this.address,
              })
              .then((response) => {
                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                localStorage.setItem("userData", JSON.stringify(response.data.userData));
                this.$ability.update(response.data.userData.ability);
                this.$router.push("/");
              })
              .catch((error) => {
                this.$refs.registerForm.setErrors(error.response.data.error);
              });
          }
        });
      } else {
        alert("Please verify you are not robot");
      }
    },
    onVerify: function (response) {
      this.registrationForm.captcha = response;
    },
    onExpired: function () {
      alert("Your verification expired, please fill all information again");
      this.registrationForm.captcha = null;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    changeCheck(val) {
      if (!val) {
        this.registrationForm.status = null;
      }
    },
    addNewLead: function(){
      if (this.registrationForm.captcha) {
        this.$refs.registerForm.validate().then((success) => {
          if (success) {
            axios.post("authentication/register/", this.registrationForm).then((response) => {
              if (response.data.status === 2000) {
                
                this.registrationForm.name = null;
                this.registrationForm.userEmail = null;
                this.registrationForm.address = null;
                this.registrationForm.handphone = null;
                this.registrationForm.captcha = null;
                this.registrationForm.status = null;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Register",
                    icon: "EditIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });

                this.$router.push({ name: "login" });
              } else {
                this.$router.push({ name: "login" });
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Register",
                    icon: "EditIcon",
                    variant: "error",
                    text: "Register Failed",
                  },
                });
              }
            }).catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Register",
                  icon: "EditIcon",
                  variant: "success",
                  text: error.response.messages,
                },
              });
            }).finally(() => {
              this.isLoading = false;
            });
          }
        });
      } else {
        alert("Please verify you are not robot");
      }
    }
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
